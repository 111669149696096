.extendable_word {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.extendable_word_title {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}
.extendable_word_list {
  width: 50vw;
  margin-top: 10px;
  overflow: hidden;
}
.extendable_word_item {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  background-color: #fbfbfb;
  border: 1px solid #e8e8e8;
  font-size: 16px;
  border-radius: 5px;
  padding: 1px 8px;
  margin: 3px 5px;
}
.extendable_word_item:active {
  background-color: #afafaf;
}
.extendable_word_item:hover {
  background-color: #f5f5f5;
}
.extendable_word_button {
  display: inline-block;
  cursor: pointer;
  background-color: #5ABDFF;
  color: #fefefe;
  font-size: 16px;
  border-radius: 5px;
  padding: 1px 8px;
  margin: 3px 5px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.extendable_word_clear {
  display: inline-block;
  cursor: pointer;
  font-size: 15px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 1px 8px;
  margin: 3px 5px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.extendable_word_clear i {
  margin: 0;
  color: #fd6363;
}
.extendable_word_clear:active {
  background-color: #afafaf;
}
.extendable_word_clear:hover {
  background-color: #f5f5f5;
}
@media (max-width: 768px) {
  .extendable_word_list {
    width: 85vw !important;
  }
}