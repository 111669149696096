.platforms {
  display: block;
  margin: auto;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}
.platforms ul, .platforms li {
  display: inline;
}
.platforms ul li {
  padding-right: 15px;
}
.platforms ul li a {
  color: #666;
  cursor: pointer;
  vertical-align: middle;
}
.platforms ul li a:hover {
  text-decoration: underline;
}
.platforms ul li a i.icon {
  height: 1.3em !important;
}