.dashboard {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.dashboard .item {
  overflow: hidden;
  background: #fff;
  display: block;
  border-radius: 10px;
  margin: 5px 5px;
  min-width: 7rem;
  padding: 0px !important;
  border: 1px solid #e1e5e5;
  z-index: 1;
  cursor: pointer;
}
.dashboard .item:hover {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.0588235294);
}
.dashboard .item .content {
  padding: 5px 5px;
  display: flex;
  justify-content: center;
}
.dashboard .item .bottom-description {
  background: #f4f3f1;
  min-width: 106px;
}
.dashboard .item .bottom-description h2 {
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  color: #5C6065;
  padding: 3px 5px;
}
.dashboard .center {
  margin: 0 !important;
  max-width: 484px;
}
.dashboard .rm-space {
  margin: 0 !important;
  padding: 0 !important;
}
.dashboard__grid {
  display: flex;
}
.dashboard__column {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .dashboard .rm-space {
    margin: 0 !important;
    padding: 0 !important;
  }
  .dashboard__column {
    flex-direction: column;
  }
}