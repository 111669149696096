.user-popup-info {
  position: relative;
}
.user-popup-info__avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #DEDEDE;
  cursor: pointer;
}
.user-popup-info__menu {
  position: absolute;
  right: 0;
  width: 140px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #dfe1e5;
  margin-top: 15px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.07);
  z-index: 99;
}
.user-popup-info__menu:after, .user-popup-info__menu:before {
  bottom: 100%;
  left: 85%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.user-popup-info__menu:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #D8D8D8;
  border-width: 8px;
  margin-left: -8px;
}
.user-popup-info__menu:before {
  border-color: rgba(216, 216, 216, 0);
  border-bottom-color: #D8D8D8;
  border-width: 9px;
  margin-left: -9px;
}
.user-popup-info__menu--show {
  display: block;
}
.user-popup-info__menu--hide {
  display: none;
}
.user-popup-info__item {
  font-style: normal;
  font-size: 1rem;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  text-align: center;
  padding: 2px 0;
}
.user-popup-info__item:hover {
  background-color: #f4f3f1;
}
.user-popup-info__item--first {
  font-weight: 500;
  font-size: 16px;
  background-color: #eeeeee;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  padding: 4px 0px;
}
.user-popup-info__item:not(:last-child) {
  border-bottom: 0.5px solid #DBDBDB;
}
.user-popup-info__item:last-child {
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

.login-button {
  margin-right: 5px;
  padding: 7px !important;
}

@media (min-width: 768px) {
  .drawer-toggle {
    display: none;
  }
}
@media (max-width: 768px) {
  .login-button {
    display: none !important;
  }
}