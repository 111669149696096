.search-filter-list {
  display: block;
  position: absolute;
  margin-top: -24px;
  margin-left: 10px;
}

.filter-item {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #c7c7c7;
  font-size: 1rem;
  padding: 5px;
  margin: 1px;
  display: inline;
  cursor: pointer;
  z-index: 3;
  background-color: #f8f9fa;
  background-image: linear-gradient(top, #f5f5f5, #f8f9fa);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#f8f9fa));
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f8f9fa);
  background-image: -moz-linear-gradient(top, #f5f5f5, #f8f9fa);
  background-image: -ms-linear-gradient(top, #f5f5f5, #f8f9fa);
  background-image: -o-linear-gradient(top, #f5f5f5, #f8f9fa);
  background-image: linear-gradient(top, #f5f5f5, #f8f9fa);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#f5f5f5",EndColorStr="#f8f9fa");
}
.filter-item:hover {
  border: 1px solid #ff2a2a;
  background-color: #f8f8f8;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f1f1f1));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -ms-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: linear-gradient(top, #f8f8f8, #f1f1f1);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#f8f8f8",EndColorStr="#f1f1f1");
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
}
.filter-item:focus {
  border: 1px solid #ff2a2a;
}
.filter-item:active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#e6e6e6), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #e6e6e6, #e6e6e6);
  background-image: -moz-linear-gradient(top, #e6e6e6, #e6e6e6);
  background-image: -ms-linear-gradient(top, #e6e6e6, #e6e6e6);
  background-image: -o-linear-gradient(top, #e6e6e6, #e6e6e6);
  background-image: linear-gradient(top, #e6e6e6, #e6e6e6);
}

.filter-item-active {
  border: 1px solid #ff2a2a;
  background-color: #f6f6f6;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f6f6f6), to(#f1f1f1));
  background-image: -webkit-linear-gradient(top, #f6f6f6, #f1f1f1);
  background-image: -moz-linear-gradient(top, #f6f6f6, #f1f1f1);
  background-image: -ms-linear-gradient(top, #f6f6f6, #f1f1f1);
  background-image: -o-linear-gradient(top, #f6f6f6, #f1f1f1);
  background-image: linear-gradient(top, #f6f6f6, #f1f1f1);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#f6f6f6",EndColorStr="#f1f1f1");
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
}