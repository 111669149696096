.search-bar-wrapper {
  width: 50vw;
  margin: auto;
  position: relative;
}

@media (max-width: 768px) {
  .search-bar-wrapper {
    width: 85vw;
    z-index: 10;
  }
}
.homepage-only {
  margin: auto;
  margin-top: 30px;
}

.input-method-area {
  position: relative;
  display: flex;
  z-index: 999;
}

.item .input-method-area {
  position: absolute;
}

.homepage-search-bar {
  display: flex;
  z-index: 3;
  height: 44px;
  margin: 0 auto;
}

.search-wrapper {
  padding: 7px 15px 7px 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #dfe1e5;
  box-shadow: none;
  border-radius: 10px;
  z-index: 10;
  background: #fff;
}
.search-wrapper:hover, .search-wrapper:focus-within {
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.08);
  border-color: rgba(0, 0, 0, 0.08);
}
.search-wrapper input {
  font-size: 1.1rem;
  line-height: 1.1rem;
  background-color: transparent;
  border: none;
  word-wrap: break-word;
  outline: none;
  display: flex;
  flex: 1;
  overflow: hidden !important;
  -webkit-tap-highlight-color: transparent;
}
.search-wrapper .icon-close, .search-wrapper .icon-search {
  color: #888;
  cursor: pointer;
}
.search-wrapper .icon-close {
  font-size: 1.2rem;
  margin-right: 10px;
}
.search-wrapper .icon-search {
  font-size: 1.2rem;
  margin-right: 0 !important;
  margin-right: -5px !important;
}
.search-wrapper .loading-icon {
  margin-top: 4px;
  animation: icon-loading 1s linear infinite !important;
}

.show-result {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.08);
  border-color: rgba(0, 0, 0, 0.08);
}

.item .search-bar-wrapper .icon-search {
  line-height: 1.7em !important;
  cursor: pointer;
}
.item .search-bar-wrapper .loading-icon {
  margin-top: 8px;
  animation: icon-loading 1s linear infinite !important;
}
.item .homepage-search-bar input {
  margin-top: 3px;
}