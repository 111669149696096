.latest_contribute_item {
  display: flex;
  cursor: pointer;
}
.latest_contribute_item + .latest_contribute_item {
  margin-top: 10px;
}
.latest_contribute_item__avatar {
  margin: 0.2rem 0 0;
  display: flex;
}
.latest_contribute_item__avatar img {
  align-self: center;
  width: 3.3rem;
  height: 3.3rem;
  border-radius: 50% !important;
  border: 1px solid #DEDEDE !important;
}
.latest_contribute_item__main {
  flex: 1;
  margin-left: 10px;
}
.latest_contribute_item__info {
  display: flex;
}
.latest_contribute_item__username {
  font-size: 14px;
  line-height: 16px;
  color: #B0B0B0;
}
.latest_contribute_item__username > span {
  color: #303030;
}
.latest_contribute_item__time {
  font-size: 14px;
  line-height: 16px;
  color: #B0B0B0;
  margin-left: auto;
}
.latest_contribute_item__content {
  background-color: #F1F3F4;
  border-radius: 10px;
  font-size: 14px;
  line-height: 16px;
  padding: 10px;
  margin-top: 3px;
}
.latest_contribute_item__content:active {
  background: #e2e2e2;
  border-radius: 5px;
}