.footer-layout {
  position: relative;
  margin-top: 20px;
  background-color: #f2f2f2;
  border-top: 1px solid #e4e4e4;
  z-index: 2;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.footer-content > div {
  line-height: 30px;
  font-size: 0.9rem;
}
.footer-content span {
  vertical-align: middle;
  padding: 0 10px;
  cursor: pointer;
  color: #70757a;
}
.footer-content span:hover {
  text-decoration: underline;
}
.footer-content span a {
  color: #70757a;
}
.footer-content img {
  vertical-align: middle;
}
.footer-content-center {
  flex-grow: 1;
  text-align: center;
}
.footer-content .desktop-only span {
  border-right: solid 1px #9c9c9c;
}
.footer-content .desktop-only span:last-child {
  border-right: none;
}
@media (max-width: 768px) {
  .footer-content .desktop-only {
    display: none;
  }
}