@charset "UTF-8";
.input-methods {
  color: #666;
  z-index: 10000;
}
.input-methods .input-method-button {
  width: 46px;
  text-align: center;
  margin: 0 2px;
  border-bottom: 3px solid transparent;
}
.input-methods .input-method-button .title {
  line-height: 0.8em;
}
.input-methods .input-method-button .input-icon {
  font-size: 30px;
  font-weight: 100;
  height: 44px;
  width: 44px;
  padding: 5px;
  color: #333333;
  border-radius: 10px;
  background-color: #f8f9fa;
  border: 1px solid #dfe1e5;
  cursor: pointer;
}
.input-methods .input-method-button .input-icon:hover {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #dadce0;
}
.input-methods .input-method-button .input-icon:focus {
  border: 1px solid #4d90fe;
}
.input-methods .input-method-button--left {
  float: left;
  margin-left: 0 !important;
}
.input-methods .input-method-button--right {
  float: right;
  margin-right: 0 !important;
}
.input-methods .input-method-button.dict-type-filter {
  min-height: 44px;
  border: 1px solid #c3c3c3;
  border-radius: 10px;
  padding: 0 !important;
  margin: 0px 0px 3px 3px !important;
  transition: ease-in-out 0.3s;
}
.input-methods .input-method-button.dict-type-filter:hover {
  background-color: #f1f3f4;
}
.input-methods .input-method-button.dict-type-filter .title {
  margin-top: 5px;
  font-size: 10px;
}
.input-methods .input-method-button.dict-type-filter .icon {
  height: 22px;
}
.input-methods .icon {
  display: block;
  margin: 0 auto;
  width: 28px;
  height: 30px;
}
.input-methods .input-radical-icon::after {
  content: "部";
}
.input-methods h4 {
  font-size: 11px;
  color: #666;
  margin: 2px 0 0 0;
}
.input-methods .active {
  border-bottom: 2px solid #ff2a2a !important;
}

.filter-button {
  display: none;
}

.input-methods-mobile {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  background-color: rgba(251, 251, 251, 0.44);
  border: 1px solid #ababab;
  padding: 2px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .input-methods {
    position: fixed;
    bottom: 1.3rem;
    right: 0.5rem;
    background-color: rgba(255, 255, 255, 0.831372549);
    border: 1px solid #e2e2e2;
    padding: 3px;
    border-radius: 10px;
    display: flex;
    padding-bottom: 0;
  }
  .input-method-button {
    float: none !important;
  }
  .input-methods-mobile {
    right: 1rem;
    left: auto;
  }
  .filter-button {
    display: block !important;
  }
}