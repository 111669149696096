.overlay-jdict {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;
  background: rgba(143, 143, 143, 0.54);
}
.overlay-jdict .overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.overlay-jdict .overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.overlay-jdict .spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 0.7s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}