.advance_search_dropdown {
  display: flex;
  flex-direction: row;
  color: #5F6368;
  margin-right: 8px;
  line-height: 1.1rem;
  cursor: pointer;
}
.advance_search_dropdown__wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: 8px;
  line-height: 1.1rem;
  cursor: pointer;
}
.advance_search_dropdown__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
}
.advance_search_dropdown__icon {
  margin-top: 3px;
  margin-left: 5px;
  font-size: 14px;
}

.dropdown_component {
  position: relative;
}
.dropdown_component__list {
  display: flex;
  flex-direction: column;
}
.dropdown_component__item {
  margin: 0;
  padding: 8px 0 8px 5px;
}
.dropdown_component__item label {
  font-size: 14px;
  color: #717171;
  padding: 0 25px;
  white-space: nowrap;
  overflow: hidden;
}
.dropdown_component__item:not(:last-child) {
  border-bottom: 0.5px solid #DBDBDB;
}
.dropdown_component__content {
  display: none;
  position: absolute;
  background-color: #fff;
  top: 25px;
  right: -12px;
  margin-top: 14px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #D8D8D8;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.07);
}
.dropdown_component__content:after, .dropdown_component__content:before {
  bottom: 100%;
  left: 80%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.dropdown_component__content:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}
.dropdown_component__content:before {
  border-color: rgba(216, 216, 216, 0);
  border-bottom-color: #D8D8D8;
  border-width: 9px;
  margin-left: -9px;
}
.dropdown_component__content--show {
  display: block;
}
.dropdown_component__content--hide {
  display: none;
}