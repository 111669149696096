.application_menu {
  position: relative;
  margin-right: 19px;
  line-height: 0rem;
}
.application_menu__icon {
  height: 28px;
  width: 28px;
  padding: 2px;
  color: #9e9e9e;
  transition: 0.2s;
  border-radius: 14px;
}
.application_menu__icon:hover {
  background: rgba(222, 235, 255, 0.6);
  color: #0052CC;
}
.application_menu__icon:active {
  background: rgba(184, 212, 253, 0.6);
  color: #0052CC;
}
.application_menu__list {
  display: grid;
  padding: 8px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 10px;
}
.application_menu__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
  transition: ease-in-out 0.3s;
}
.application_menu__item:hover {
  background: #ececec;
}
.application_menu__item img {
  width: 42px;
  height: 42px;
  border-radius: 5px;
}
.application_menu__item span {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  text-align: center;
}