.hot_word {
  width: 250px;
}
.hot_word_title {
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.353365px;
  line-height: 25px;
}
.hot_word_list {
  margin-top: 20px;
}
.hot_word_item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.hot_word_item + .hot_word_item {
  padding-top: 5px;
}
.hot_word_item:not(:last-child) {
  padding-bottom: 5px;
  border-bottom: 1px solid #E8E8E8;
}
.hot_word_item__index {
  padding: 2px 10px;
  background-color: #2185d0;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 8px;
}
.hot_word_item__content {
  flex: 1;
  font-size: 16px;
  letter-spacing: 0.269231px;
  line-height: 19px;
}
.hot_word_item__detail_button {
  color: #d3d3d3;
}
.hot_word_item:active {
  background: #f4f3f1;
}