.advance_search {
  position: absolute;
  bottom: -30px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #f8f9fa;
  border: 1px solid #dfe1e5;
  border-radius: 0px 0px 10px 10px;
  margin-left: 10px;
  padding: 4px 6px 0px 6px;
}
.advance_search__content {
  margin-left: 1rem;
}
.advance_search__title {
  white-space: nowrap;
}
.advance_search__radio + .advance_search__radio {
  margin-left: 1rem;
}
@media (max-width: 768px) {
  .advance_search__radio + .advance_search__radio {
    margin-left: 1rem !important;
  }
}