.hover_menu {
  position: relative;
}
.hover_menu__content {
  z-index: 999;
  display: none;
  position: absolute;
  right: -18px;
  background-color: #fff;
  margin-top: 5px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #D8D8D8;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.07);
}
.hover_menu__content:after, .hover_menu__content:before {
  bottom: 100%;
  left: 80%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.hover_menu__content:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}
.hover_menu__content:before {
  border-color: rgba(216, 216, 216, 0);
  border-bottom-color: #D8D8D8;
  border-width: 9px;
  margin-left: -9px;
}
.hover_menu__content--show {
  display: block;
}
.hover_menu__content--hide {
  display: none;
}