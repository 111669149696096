.image {
  position: relative;
  vertical-align: middle;
  max-width: 100%;
  background-color: transparent;
  display: block;
}
.image--avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.image--centered {
  margin-right: auto;
  margin-left: auto;
}