.homepage {
  padding-top: 12vh;
  padding-bottom: 20px;
  position: relative;
}
.homepage .logo {
  width: 256px;
  margin-bottom: 5px;
}

.homepage_daily_info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 40px;
}
.homepage_daily_info__content {
  width: 23vw !important;
}
.homepage_daily_info__divider {
  width: 50px;
}

@media (max-width: 768px) {
  .homepage_daily_info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .homepage_daily_info__content {
    width: 83vw !important;
    margin-bottom: 50px;
  }
  .homepage_daily_info__divider {
    display: none !important;
  }
}
.home-page-bg {
  background: #fff;
}

.searchbar-container {
  width: 100%;
  text-align: center;
}