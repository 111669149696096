.suggest-result-box {
  position: relative;
  display: flex;
}

.item .suggest-result-box {
  position: absolute;
  width: 100%;
}

.result-box {
  max-height: 300px;
  width: 100%;
  position: absolute;
  overflow-y: hidden;
  background: #fff;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  z-index: 9;
  padding: 0 0 2px 0;
  border: solid rgba(0, 0, 0, 0.08);
  border-width: 0px 1px 1px 1px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.08);
}
.result-box div:last-child::after {
  content: "";
  display: block;
  border-bottom: none;
}
.result-box-top-divider {
  border-top: 1px solid #e8eaed;
  margin: 0 20px;
  padding-bottom: 4px;
}
.result-box--description {
  font-size: 1rem;
  padding: 5px 10px;
  display: flex;
  border-bottom: 1px solid #e8eaed;
  line-height: 1em;
}
.result-box--description a:hover {
  cursor: pointer;
}
.result-box--description .left {
  align-self: flex-start;
}
.result-box--description .right {
  margin-left: auto;
}
.result-box .results {
  overflow: auto;
  /* Track */
  /* Handle */
}
.result-box .results--empty {
  font-size: 1rem;
  text-align: center;
}
.result-box .results::-webkit-scrollbar {
  width: 3px;
}
.result-box .results::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border: 4px solid transparent;
  background-clip: content-box; /* THIS IS IMPORTANT */
}
.result-box .results::-webkit-scrollbar-thumb {
  background: #fe4e4e;
  border-radius: 50px;
}
.result-box .result {
  cursor: pointer;
  display: block;
  overflow: hidden;
  font-size: 1em;
  padding: 10px 10px 0 10px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.33;
  text-align: left;
}
.result-box .result::after {
  content: "";
  display: block;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}
.result-box .active {
  background-color: #e4e5e6;
}
.result-box .results > :first-child {
  border-radius: 0.28571429rem 0.28571429rem 0 0;
}
.result-box .result-item {
  font-size: 1rem;
}
.result-box .result-type {
  float: right;
  font-weight: 400 !important;
  background-color: gray;
  color: white;
  padding: 5px;
  border-radius: 4px;
}