.radical-area-wrapper {
  position: absolute;
  border: 1px solid #ebebeb;
  border-width: 0px 1px 1px 1px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
  background: #fff;
  z-index: 21;
}

.radical-area .viewing_all {
  max-height: 250px !important;
  overflow: auto !important;
}
.radical-area .viewing_normal {
  max-height: 74px;
  overflow: hidden;
}
.radical-area .show_more {
  line-height: 1;
  text-align: right;
  font-size: 14px;
  cursor: pointer;
  margin: 0 10px 5px 0;
}
.radical-area .results {
  position: relative;
}
.radical-area .results .showing_results {
  /* Track */
  /* Handle */
}
.radical-area .results .showing_results::-webkit-scrollbar {
  width: 3px;
}
.radical-area .results .showing_results::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border: 4px solid transparent;
  background-clip: content-box; /* THIS IS IMPORTANT */
}
.radical-area .results .showing_results::-webkit-scrollbar-thumb {
  background: #fe4e4e;
  border-radius: 50px;
}
.radical-area .results .result-label {
  color: #525252;
  background: none;
  min-width: 32px;
  line-height: 36px;
  font-weight: bold;
  font-size: 20px;
  padding: 0 5px;
  display: inline-block;
  border-radius: 2px;
  text-align: center;
  margin: 1px;
  height: 36px;
  width: 36px;
}
.radical-area .results .result {
  display: inline-block;
  border-radius: 2px;
  background-color: #eaeaea;
  font-size: 24px;
  text-align: center;
  margin: 1px;
  height: 36px;
  width: 36px;
  padding: 2px 4px;
  line-height: 1.4;
  cursor: pointer;
}
.radical-area .results .list {
  margin-bottom: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  width: auto;
}
.radical-area .overflowing {
  margin-bottom: 10px;
}

.radical_table {
  background: -webkit-repeating-linear-gradient(top, #fff, #fff 32px, #EDF9FF 32px, #EDF9FF 64px);
  background: repeating-linear-gradient(180deg, #fff, #fff 32px, #EDF9FF 32px, #EDF9FF 64px);
  background-color: #efefef;
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
  list-style: none;
  text-align: center;
  margin: 0;
  z-index: 777;
  max-height: 240px;
  overflow-y: scroll;
  overflow-wrap: break-word;
  position: relative;
  /* Track */
  /* Handle */
}
.radical_table::-webkit-scrollbar {
  width: 3px;
}
.radical_table::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border: 4px solid transparent;
  background-clip: content-box; /* THIS IS IMPORTANT */
}
.radical_table::-webkit-scrollbar-thumb {
  background: #fe4e4e;
  border-radius: 50px;
}
.radical_table .number {
  font-size: 14px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin: 4px;
  color: #ccc;
  background-color: #333;
  font-weight: bold;
  border-radius: 2px;
  z-index: 100;
  float: left;
}
.radical_table .selected {
  border: 2px solid rgba(0, 0, 0, 0.4);
  background-color: rgba(255, 255, 0, 0.2);
  border-radius: 50%;
}
.radical_table .not-available {
  pointer-events: none;
  opacity: 0.4;
}
.radical_table .radical {
  font-size: 22px;
  width: 32px;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  color: #333;
  z-index: 100;
  border: 2px solid transparent;
  float: left;
}
.radical_table .radical:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.radical_table .reset_radicals {
  position: fixed;
  float: left;
  height: 30px;
  background: #ff2a2a;
  width: 30px;
  text-align: center;
  margin: 0;
  padding: 5px;
  cursor: pointer;
  color: #fff;
}