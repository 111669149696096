.homepage-ads-wrapper {
  width: 50vw;
  margin: 10px auto;
  border: 1px solid #f4f3f1;
  border-radius: 10px;
  position: relative;
  height: 60px !important;
  z-index: 0;
}

.ad-inner {
  z-index: 0;
  width: 50vw;
  height: 60px !important;
}

@media (max-width: 768px) {
  .homepage-ads-wrapper {
    width: 85vw;
  }
  .homepage-ads-wrapper .ad-inner {
    width: 100%;
    height: 40px !important;
  }
}