@media (max-width: 768px) {
  .desktop-auth-area {
    display: none;
    line-height: 1em;
  }
  .mobile-auth-area {
    display: block !important;
    line-height: 1em;
  }
}
@media (min-width: 768px) {
  .desktop-auth-area {
    display: block;
    line-height: 1em;
  }
  .mobile-auth-area {
    display: none !important;
    line-height: 1em;
  }
}
.home_navbar {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 16px;
}
.home_navbar__left {
  flex: 1;
}
.home_navbar__right {
  display: flex;
  align-items: center;
}