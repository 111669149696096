.side-drawer {
  position: fixed;
  width: 240px;
  max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 10000;
  background-color: white;
  visibility: hidden;
  transform: translateX(-100%);
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  box-shadow: 1px 0px 10px 5px rgba(34, 36, 38, 0.08);
}
.side-drawer-menu {
  height: 100%;
  width: 100% !important;
}

.logout-button {
  position: absolute !important;
  bottom: 0;
  background-color: #ff2a2a !important;
  color: white !important;
  width: 100%;
  border-radius: 0px !important;
}

.lgin-button {
  position: absolute !important;
  bottom: 0;
  background-color: rgb(79, 109, 241) !important;
  width: 100%;
  color: #fff !important;
  border-radius: none !important;
}

.ui.vertical.menu > .item:first-child, .item:last-child {
  border-radius: none !important;
}

.ui.menu.side-drawer-menu {
  border: none;
  border-radius: 0px !important;
  overflow-x: auto;
}

@media (min-width: 768px) {
  .side-drawer {
    display: none;
  }
}
.side-drawer-open {
  transform: translateX(0);
  visibility: visible;
  display: block;
}

.side-drawer-close {
  transform: translateX(-100%);
  visibility: visible;
  display: block;
}