.hand-writing-area {
  min-height: 230px;
  min-width: 200px;
  position: relative;
  overflow: hidden;
  width: 50vw;
}

@media (max-width: 768px) {
  .hand-writing-area {
    width: 80vw;
  }
}
.margin-btm {
  margin-top: 49px;
}

.hand-writing-area-wrapper {
  display: flex;
  position: absolute;
  width: 100%;
  overflow: hidden;
  background: #fff;
  border: solid rgba(0, 0, 0, 0.08);
  border-width: 0px 1px 1px 1px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.08);
  z-index: 1;
}

.draw-kanji-result {
  overflow: auto;
  white-space: nowrap;
  flex-grow: 1;
  background: #fff;
}
.draw-kanji-result span:last-child {
  border-right: none;
}

.draw-kanji-top-bar {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
}

.writing-button-area {
  height: 49px;
  margin-left: auto;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  padding-left: 3px;
  justify-content: space-around;
}
.writing-button-area .button {
  background-color: #f5f5f5 !important;
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f1f1f1);
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.writing-button-area > div {
  display: flex;
}

.writing-option-button {
  margin-left: 3px !important;
}
.writing-option-button:active {
  background-image: -webkit-linear-gradient(top, #cacaca, #a3a3a3);
}

.canvas-draw-kanji {
  border-top: solid 0.5px #ebebeb;
  border-bottom: solid 0.5px #ebebeb;
  z-index: 999;
}

.hand-writing-placeholder {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  line-height: 49px;
  font-size: 20px;
}

.draw-kanji-suggest {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  line-height: 49px;
  padding: 0 14px 0 14px;
  border-right: 1px solid #ccc;
  color: #222;
  cursor: pointer;
  font-size: 24px;
  background-color: #fff;
}
.draw-kanji-suggest:hover {
  background-color: gainsboro;
}

@media (max-width: 768px) {
  .draw-kanji-suggest {
    line-height: 24px;
    width: 30px;
    padding: 0 5px 0 5px;
    font-size: 18px;
  }
  .writing-button-area {
    height: 32px;
  }
  .writing-button-area > div {
    height: 24px;
  }
  .writing-button-area .button {
    height: 24px !important;
  }
  .writing-button-area .button i {
    font-size: 1rem !important;
    line-height: 0.2rem;
  }
  .draw-kanji-result {
    min-height: 41px;
  }
  .margin-btm {
    margin-top: 32px;
  }
}