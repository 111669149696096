.drawer-toggle {
  width: 40px;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  color: #9e9e9e;
  cursor: pointer;
}
.drawer-toggle i {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .drawer-toggle {
    display: none;
  }
}